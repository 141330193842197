<template>
  <div id="inf_win">
    <header>
      <div id="logo">
        <img width="160" height="19" src="@/assets/logo.svg" alt="logo" />
      </div>
      <change-locale id="locale"></change-locale>
    </header>
    <router-view />
  </div>
</template>

<script>
import changeLocale from "@/components/components/changeLocale";

export default {
  name: "AppLayoutLogin",
  components: {
    changeLocale,
  },
  created() {
    this.$store.dispatch("clear_filters");
  },
};
</script>

<style scoped>
#inf_win {
  width: 1080px;
}

header {
  display: flex;
  flex-direction: row;
  align-items: center;

  height: 120px;
  margin-bottom: 56px;

  border-bottom: 1px #c4c4c4 solid;
}
header #logo {
  text-align: start;
  flex-grow: 1;
}
header #locale {
}
</style>
